<template>
  <v-card class="mb-5" elevation="2" min-height="100vh">
    <!-- Formulario de nueva denuncia (Demo) -->
    <v-card-title class="text-h5">
      Por favor, proporcione la siguiente información (Demo):
    </v-card-title>

    <v-card-text>
      <v-form ref="createComplaintForm" v-model="valid" @submit.prevent="createComplaint">
        <v-stepper
          linear
          :items="[
            'Localización',
            'Datos denunciante',
            'Tipo de la denuncia',
            'Acción denunciada',
            'Testigos',
            'Valor y documentación',
            'Confirmación',
          ]"
        >
          <!-- Paso 1: Localización -->
          <template v-slot:item.1>
            <v-select
    label="Entidad/Organización *"
    :items="['Empresa Demo']"
    v-model="newComplaint.company"
    :rules="[rules.required]"
  ></v-select>

            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <span class="text-subtitle-1 font-weight-medium">
                Lugar donde se produce el incidente:
                <i>(Indique dirección física, sucursal, nº de establecimiento, etc.)</i>
              </span>
            </v-sheet>

            <v-text-field
              label="Calle *"
              v-model="newComplaint.street"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              label="Código Postal *"
              v-model="newComplaint.postalCode"
              :rules="[rules.required, rules.postalCodeFormat]"
            ></v-text-field>
            <v-text-field
              label="Ciudad *"
              v-model="newComplaint.city"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              label="País *"
              v-model="newComplaint.country"
              :rules="[rules.required]"
            ></v-text-field>
          </template>

          <!-- Paso 2: Datos denunciante -->
          <template v-slot:item.2>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <span class="text-subtitle-1 font-weight-medium">
                ¿Es usted empleado de la empresa denunciada?
              </span>
            </v-sheet>
            <v-radio-group
              v-model="newComplaint.companyWorker"
              row
              :rules="[rules.required]"
            >
              <v-radio label="Sí" value="1" color="primary"></v-radio>
              <v-radio label="No" value="0" color="primary"></v-radio>
            </v-radio-group>

            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <span class="text-subtitle-1 font-weight-medium">
                ¿Desea permanecer anónimo en la presente denuncia?
              </span>
            </v-sheet>
            <v-radio-group v-model="newComplaint.complainantAnonimous" row>
              <v-radio label="Sí" :value="true" color="primary"></v-radio>
              <v-radio label="No" :value="false" color="primary"></v-radio>
            </v-radio-group>

            <v-sheet
              v-if="!newComplaint.complainantAnonimous"
              class="px-1 py-1"
              elevation="0"
              color="transparent"
            >
              <span class="text-subtitle-1 font-weight-medium">
                Si desea que nuestro equipo mediador conozca su identidad,
                complete los siguientes datos:
              </span>
            </v-sheet>
            <v-text-field
              v-if="!newComplaint.complainantAnonimous"
              label="Nombre y apellidos denunciante"
              v-model="newComplaint.complainantFullName"
            ></v-text-field>
            <v-text-field
              v-if="!newComplaint.complainantAnonimous"
              label="Número de Teléfono denunciante"
              v-model="newComplaint.complainantPhoneNumber"
            ></v-text-field>

            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-bold text-red">
                Su dirección de correo solo podrá ser vista por nuestro equipo mediador.
              </p>
            </v-sheet>
            <v-text-field
              label="Correo electrónico denunciante *"
              v-model="newComplaint.complainantEmail"
              :rules="[rules.required, rules.emailFormat]"
            ></v-text-field>
          </template>

          <!-- Paso 3: Tipo de la denuncia -->
          <template v-slot:item.3>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <span class="text-subtitle-1 font-weight-medium">
                Seleccione el tipo de denuncia que desea realizar *.
              </span>
            </v-sheet>

            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <span class="text-subtitle-1 font-weight-medium">
                Infracciones en el ámbito de: * 
                <i>(escoja al menos una opción)</i>
              </span>
            </v-sheet>

            <v-checkbox
              density="compact"
              v-for="infraction in infractions"
              :key="infraction.infractionId"
              :label="infraction.text"
              :value="infraction.infractionId"
              v-model="selectedInfractions"
              :rules="[rules.requiredInfraction]"
              class="custom-checkbox-spacing"
            ></v-checkbox>

            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                Le rogamos que identifique a la(s) persona(s) involucrada(s).
                <i>(Dejar vacio si no se puede identificar a nadie)</i>
              </p>
            </v-sheet>
            <v-row>
              <!-- Para el primer nombre y cargo -->
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newComplaint.defendantFullName"
                  label="Nombre y apellidos"
                  placeholder="Introduce nombre y apellidos"
                  class="custom-v-text-field-spacing"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newComplaint.defendantJob"
                  label="Cargo/puesto"
                  placeholder="Introduce cargo/puesto"
                  class="custom-v-text-field-spacing"
                ></v-text-field>
              </v-col>

              <!-- Para el segundo nombre y cargo -->
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newComplaint.defendantFullName2"
                  label="Nombre y apellidos"
                  placeholder="Introduce nombre y apellidos"
                  class="custom-v-text-field-spacing"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newComplaint.defendantJob2"
                  label="Cargo/puesto"
                  placeholder="Introduce cargo/puesto"
                  class="custom-v-text-field-spacing"
                ></v-text-field>
              </v-col>

              <!-- Para el tercer nombre y cargo -->
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newComplaint.defendantFullName3"
                  label="Nombre y apellidos"
                  placeholder="Introduce nombre y apellidos"
                  class="custom-v-text-field-spacing"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newComplaint.defendantJob3"
                  label="Cargo/puesto"
                  placeholder="Introduce cargo/puesto"
                  class="custom-v-text-field-spacing"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <!-- Paso 4: Acción denunciada -->
          <template v-slot:item.4>
            <v-card-title class="text-h5 formTitles">
              Acción denunciada
            </v-card-title>

            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                Describa de manera general la naturaleza de los hechos: *
              </p>
            </v-sheet>
            <v-textarea
              v-model="newComplaint.complainantDescription"
              label="Descripción de los hechos denunciados *"
              placeholder="Descripción"
              :rules="[rules.required]"
            ></v-textarea>

            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                Indique el lugar donde se ha producido y el departamento o área afectada:
              </p>
            </v-sheet>
            <v-text-field
              v-model="newComplaint.complainantPlace"
              label="Lugar del incumplimiento normativo *"
              placeholder="Lugar"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="newComplaint.complainantDepartment"
              label="Departamento o área afectada *"
              placeholder="Departamento o área"
              :rules="[rules.required]"
            ></v-text-field>

            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                Indique fecha o periodo temporal de los hechos:
              </p>
            </v-sheet>
            <v-text-field
              v-model="newComplaint.complaintDate"
              label="Fecha del incumplimiento normativo *"
            ></v-text-field>
            <v-text-field
              v-model="newComplaint.complaintTime"
              label="Hora en la que se produjeron los hechos *"
              placeholder="Hora"
              :rules="[rules.required]"
            ></v-text-field>

            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                ¿Cómo se dio cuenta del incumplimiento normativo? *
              </p>
            </v-sheet>
            <v-select
              v-model="newComplaint.modeOfDiscovery"
              :items="modeOfDiscoveryOptions"
              label="Modo en el que descubrió los hechos *"
              outlined
              :rules="[rules.requiredOption]"
            ></v-select>
          </template>

          <!-- Paso 5: Testigos -->
          <template v-slot:item.5>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                Indique el nombre y apellidos de los testigos:
                <i>(Dejar vacio si no existen testigos)</i>
              </p>
            </v-sheet>
            <v-card-title class="text-h5 formTitles">Testigos</v-card-title>
            <v-row>
              <!-- Testigo 1 -->
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newComplaint.witnessFullName"
                  label="Nombre y apellidos"
                  class="custom-v-text-field-spacing"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newComplaint.witnessJob"
                  label="Cargo/puesto"
                  class="custom-v-text-field-spacing"
                ></v-text-field>
              </v-col>

              <!-- Testigo 2 -->
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newComplaint.witnessFullName2"
                  label="Nombre y apellidos"
                  class="custom-v-text-field-spacing"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newComplaint.witnessJob2"
                  label="Cargo/puesto"
                  class="custom-v-text-field-spacing"
                ></v-text-field>
              </v-col>

              <!-- Testigo 3 -->
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newComplaint.witnessFullName3"
                  label="Nombre y apellidos"
                  class="custom-v-text-field-spacing"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newComplaint.witnessJob3"
                  label="Cargo/puesto"
                  class="custom-v-text-field-spacing"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <!-- Paso 6: Valor y documentación -->
          <template v-slot:item.6>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                ¿En cuánto estima el valor monetario de la infracción? *
              </p>
            </v-sheet>
            <v-select
              label="Seleccione un valor monetario"
              :rules="[rules.requiredValue]"
              :items="valuecomplaintOptions"
              v-model="newComplaint.valuecomplaint"
            ></v-select>

            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-medium">
                Puede adjuntar documentos o archivos que prueben la denuncia:
              </p>
              <p class="text-subtitle-1 font-weight-medium">
                <i>(Por ejemplo: fotografías)</i>
              </p>
            </v-sheet>
            <v-file-input
              label="Adjuntar archivos"
              v-model="newComplaint.files"
              accept="image/png, image/jpeg, application/pdf, video/x-matroska"
              multiple
              name="files"
              :disable="isFileInputDisabled"
            ></v-file-input>
          </template>

          <!-- Paso 7: Confirmación -->
          <template v-slot:item.7>
            <v-sheet class="px-1 py-1" elevation="0" color="transparent">
              <p class="text-subtitle-1 font-weight-bold">
                DECLARACIÓN DE BUENA FE Y ACEPTACIÓN DE LOS TÉRMINOS
              </p>
              <p class="text-subtitle-1 font-weight-medium">
                Manifiesto que la presente comunicación la realizo de buena fe...
              </p>
            </v-sheet>
            <v-checkbox
              label="Acepto los términos y la política de privacidad *"
              v-model="newComplaint.termsAccepted"
              :rules="[rules.requiredCheckbox]"
            ></v-checkbox>
            <v-spacer></v-spacer>
            <!-- Botón para enviar (demo) -->
            <v-btn color="primary" text @click="createComplaint">
              Crear (Demo)
            </v-btn>
          </template>
        </v-stepper>
      </v-form>
    </v-card-text>

    <!-- Snackbar de notificación -->
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      bottom
      right
    >
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Cerrar</v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      valid: true, // Para el VForm
      // Datos de denuncia (Demo)
      newComplaint: {
        street: "",
        postalCode: "",
        city: "",
        country: "",
        companyWorker: false,
        complainantAnonimous: false,
        complainantFullName: "",
        complainantPhoneNumber: "",
        complainantEmail: "",
        companyId: 1, // Selección de la empresa
        defendantFullName: "",
        defendantFullName2: "",
        defendantFullName3: "",
        defendantJob: "",
        defendantJob2: "",
        defendantJob3: "",
        complainantDescription: "",
        complainantPlace: "",
        complainantDepartment: "",
        complaintDate: "",
        complaintTime: "",
        modeOfDiscovery: "",
        witnessFullName: "",
        witnessFullName2: "",
        witnessFullName3: "",
        witnessJob: "",
        witnessJob2: "",
        witnessJob3: "",
        valuecomplaint: "",
        termsAccepted: false,
        files: [],
      },
      // Opciones de ejemplo
      companies: [
        { companyId: 1, company_name: "Empresa Demo 1" },
        { companyId: 2, company_name: "Empresa Demo 2" },
      ],
      infractions: [
        { infractionId: 1, text: "Contratación pública" },
        { infractionId: 2, text: "Servicios,productos y mercados financieros, y prevención del blanqueo de capitales y financiación del terrorismo" },
        { infractionId: 3, text: "Seguridad de los productos y conformidad" },
        { infractionId: 4, text: "Seguridad del transporte" },
        { infractionId: 5, text: "Protección del medio ambiente" },
        { infractionId: 6, text: "Protección frente a las radiaciones y seguridad nuclear" },
        { infractionId: 7, text: "Alimenticio: seguridad de los alimentos y los piensos, sanidad animal y bienestar de los animales" },
        { infractionId: 8, text: "Protección de los consumidores" },
        { infractionId: 9, text: "Legislación mercantil" },
        { infractionId: 10, text: "Legislación de la seguridad social, normativa tributaria, normativa de prevención de riesgos laborales" },
        { infractionId: 11, text: "Derecho de los trabajadores. Acoso laboral y/o sexual. Delito contra el derecho de los trabajadores" },
        { infractionId: 12, text: "Protección de la privacidad y de los datos personales, y seguridad de las redes y los sistemas de información" },
        { infractionId: 13, text: "Infracciones que afecten a los intereses financieros de la Unión tal como se contemplan en el artículo 325 del TFUE y tal como se concretan en las correspondientes medidas de la unión" },
        { infractionId: 14, text: "Infracciones relativas al mercado interior, tal como se contemplan en el artículo 26, apartado 2, del TFUE, incluidas las infracciones de las normas de la Unión en materia de competencia y ayudas otorgadas por los Estados, así como las infracciones relativas al mercado interior en relación con los actos que infrinjan las normas del impuesto sobre sociedades o a prácticas cuya finalidad sea obtener una ventaja fiscal que desvirtúe el objeto o finalidad de la legislación aplicable del impuesto de sociedades" },





      ],
      selectedInfractions: [],
      modeOfDiscoveryOptions: [
        "Me ha sucedido a mí",
        "Fui testigo directo",
        "Me lo dijo un compañero",
        "Persona externa a la entidad",
        "Encontré un documento accidentalmente",
        "Otro",
      ],
      valuecomplaintOptions: [
        "De 1€ a 1.000 €",
        "De 1.001 € a 10.000 €",
        "De 10.001 € a 12.000 €",
        "De 12.001 € a 1 Millón €",
        "+ de 1 Millón de €",
        "No sabe cuantificarlo",
      ],
      // Reglas de validación (Vuetify)
      rules: {
        required: (value) => !!value || "Requerido",
        requiredCompany: (value) => !!value || "Debe seleccionar una empresa",
        requiredCheckbox: (value) => value || "Debe aceptar los términos",
        requiredValue: (value) => !!value || "Debe seleccionar un valor estimado",
        requiredInfraction: (value) =>
          value && value.length > 0 || "Debe escoger al menos una opción",
        requiredOption: (value) => !!value || "Debe seleccionar una opción",
        postalCodeFormat: (value) =>
          /^\d{5}$/.test(value) || "Introduzca un código postal válido (5 dígitos)",
        emailFormat: (value) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(value) || "Introduzca un correo electrónico válido";
        },
      },
      snackbar: {
        show: false,
        color: "success",
        message: "",
      },
    };
  },
  methods: {
    // Método que simula la "creación" de la denuncia (solo demo)
    async createComplaint() {
      // Validamos el formulario completo
      const formIsValid = await this.$refs.createComplaintForm.validate();
      if (formIsValid) {
        // Aquí podrías hacer la lógica para enviar datos a una API, etc.
        // En la versión demo, solo mostraremos los datos y un mensaje
        console.log("Datos de la denuncia (demo):", this.newComplaint);
        console.log("Infracciones seleccionadas:", this.selectedInfractions);

        // Notificar éxito (demo)
        this.snackbar.message = "Denuncia creada correctamente (Demo)";
        this.snackbar.color = "success";
        this.snackbar.show = true;

        // Reiniciamos el formulario
        this.resetNewComplaint();
        this.$refs.createComplaintForm.reset();
      } else {
        // Si faltan campos requeridos, avisamos
        this.snackbar.message = "Por favor, complete los campos requeridos";
        this.snackbar.color = "error";
        this.snackbar.show = true;
      }
    },
    // Reiniciar la información del formulario
    resetNewComplaint() {
      this.newComplaint = {
        street: "",
        postalCode: "",
        city: "",
        country: "",
        companyWorker: false,
        complainantAnonimous: false,
        complainantFullName: "",
        complainantPhoneNumber: "",
        complainantEmail: "",
        companyId: null,
        defendantFullName: "",
        defendantFullName2: "",
        defendantFullName3: "",
        defendantJob: "",
        defendantJob2: "",
        defendantJob3: "",
        complainantDescription: "",
        complainantPlace: "",
        complainantDepartment: "",
        complaintDate: "",
        complaintTime: "",
        modeOfDiscovery: "",
        witnessFullName: "",
        witnessFullName2: "",
        witnessFullName3: "",
        witnessJob: "",
        witnessJob2: "",
        witnessJob3: "",
        valuecomplaint: "",
        termsAccepted: false,
        files: [],
      };
      this.selectedInfractions = [];
    },
  },
};
</script>

<style scoped>
.v-card-actions {
  justify-content: flex-end;
}

/* Ajuste de margen para campos de texto, si deseas */
.custom-v-text-field-spacing {
  margin-bottom: -30px;
}
</style>
