import { createRouter, createWebHistory } from "vue-router";
import MainView from "./views/MainView.vue";
import AboutUsView from "./views/AboutUsView.vue";
import LandingPage from "./views/LandingPage.vue";
import LoginForm from "./components/LoginForm.vue";
import RegistrationForm from "./views/RegistrationForm";
import HomePage from "./views/HomePage.vue";
import CompaniesView from "./views/CompaniesView.vue";
import ComplaintsView from "./views/ComplaintsView.vue";
import UsersView from "./views/UsersView.vue";
import ResellersView from "./views/ResellersView.vue";
import ForgotPassword from "./views/ForgotPassword.vue";
import ResetPassword from "./views/ResetPassword.vue";
import ComplainantView from './views/ComplainantView.vue';
import ComplaintCreated from './views/ComplaintCreated.vue';
import NotFound from './views/NotFound.vue';
import PrivacyPolicy from './views/PrivacyPolicy.vue';
import CookiePolicy from './views/CookiePolicy.vue';
import LegalView from './views/LegalView.vue';
import FaqView from './views/FaqView.vue';
import FormComplaintsDemo from "./components/FormComplaintsDemo.vue";
import store from "@/store";

const routes = [
  { path: "/", component: MainView, name: "mainview" },
  { path: "/acerca-de", component: AboutUsView},
  { path: "/:hashedCompanyId", component: LandingPage, props: true, },
  { path: "/login", component: LoginForm },
  { path: "/registro", component: RegistrationForm, meta: { requiresAuth: true } },
  { path: "/home", component: HomePage, meta: { requiresAuth: true } },
  { path: "/empresas", component: CompaniesView, meta: { requiresAuth: true } },
  { path: "/denuncias", component: ComplaintsView, meta: { requiresAuth: true } },
  { path: "/usuarios", component: UsersView, meta: { requiresAuth: true } },
  { path: "/resellers", component: ResellersView, meta: { requiresAuth: true } },
  { path: "/denuncia-creada", component: ComplaintCreated },
  { path: "/denunciante", component: ComplainantView, meta: { requiresAuth: true } },
  { path: '/', redirect: '/no-encontrado' },
  { path: "/no-encontrado", component: NotFound },
  {path: "/formdemo",component:FormComplaintsDemo},

  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  { path: "/politica-privacidad", component: PrivacyPolicy },
  { path: '/politica-cookies', component: CookiePolicy, name: 'cookie-policy' },
  { path: '/aviso-legal', component: LegalView },
  { path: '/faq', component: FaqView},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Agrega una guarda de navegación para verificar la autenticación
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.user) {
    // La ruta requiere autenticación y el usuario no está autenticado
    next("/no-encontrado"); // Redirige a la página estática
  } else if (to.path === "/login" && store.state.user) {
    // Si el usuario ya tiene una sesión, redirige a la página de inicio
    next("/home");
  } else {
    next(); // Continúa la navegación normalmente
  }
});

export default router;
